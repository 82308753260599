<style>
  .avatar-bagde > .v-badge__badge {
    bottom: 15px !important;
    margin-left: 20px !important;
  }
</style>
<template>
  <v-container grid-list-lg>
    <v-form
      v-model="valid"
      class="form-change-picture"
      lazy-validation>
      <div class="height-auto">
        <v-layout
          row
          justify-center
          wrap>
          <v-toolbar
            class="elevation-0 edit-actions"
            height="23"
            width="100%"
            color="transparent">
            <v-spacer />

            <v-btn
              title="Salvar edição"
              class="mt-4"
              depressed
              :disabled="!valid || isLoading"
              x-small
              @click="savePicture">
              Salvar
            </v-btn>
          </v-toolbar>

          <div
            class="justify-center"
            @click="$refs.file.click()">
            <form onsubmit="return false;">
              <input id="file" ref="file" type="file" style="display: none" @change="onFileChange()">
            </form>
            <v-badge
              class="avatar-bagde"
              :color="color"
              left
              bottom
              overlap>
              <v-avatar
                class="justify-center"
                color="primary"
                size="180px">
                <user-avatar
                  :image="fileURL" />
              </v-avatar>
              <template v-slot:badge>
                <v-icon
                  dark>
                  {{ icon }}
                </v-icon>
              </template>
            </v-badge>
          </div>
        </v-layout>
      </div>
    </v-form>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import validate from '@/mixins/validate'
  import UserAvatar from '@/components/ui/UserAvatar'

  export default {
    components: { UserAvatar },
    mixins: [validate],
    data () {
      return {
        valid: true,
        file: '',
        fileURL: '',
        saved: true
      }
    },
    computed: {
      icon () {
        return this.saved ? 'mdi-check' : 'mdi-alert'
      },
      color () {
        return this.saved ? 'green' : 'red'
      },
      ...mapGetters({
        me: 'user/getUserInfo',
        isLoading: 'app/getLoading'
      })
    },
    mounted (){
      this.fileURL = this.me.photoURL
    },
    methods: {
      onFileChange() {
        if (!this.$refs.file.files || !this.$refs.file.files.length || !this.$refs.file.files[0]) {
          return
        }
        this.$store.commit('app/setLoading', true)
        this.file = this.$refs.file.files[0]
        const fileReader = new FileReader()
        fileReader.onload = _ => {
          this.saved = false
          this.fileURL = fileReader.result
          this.$store.commit('app/setLoading', false)
        }
        fileReader.readAsDataURL(this.file)
      },
      savePicture() {
        if(!this.file){
          return
        }
        this.$store
          .dispatch('user/changePicture', this.file)
          .then(this._success)
          .catch(this._failure)
      },
      clear (){
        this.file = ''
        this.fileURL = ''
      },
      _success () {
        this.saved = true
        this.clear()
        this.fileURL = this.me.photoURL
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Avatar alterado com sucesso'})
        this.$emit('pictureChanged')
      },
      _failure (message) {
        this.saved = false
        this.clear()
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      }
    }
  }
</script>

