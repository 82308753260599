<template>
  <v-container grid-list-lg>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <div class="height-auto">
        <v-layout
          class="layout-block"
          row
          wrap>
          <v-toolbar
            class="elevation-0 edit-actions"
            height="23"
            width="100%"
            color="transparent">
            <v-spacer />

            <v-btn
              title="Salvar edição"
              class="mt-4"
              depressed
              :disabled="!valid || isLoading"
              x-small
              @click="changePassword">
              Salvar
            </v-btn>
          </v-toolbar>

          <v-flex
            xs12
            sm4
            class="full-flex">
            <v-text-field
              v-model="currentPassword"
              label="Senha Atual"
              type="password"
              autocomplete="off"
              :rules="requiredRules" />
          </v-flex>

          <v-flex
            xs12
            sm4
            class="full-flex">
            <v-text-field
              v-model="newPassword"
              label="Nova Senha"
              type="password"
              autocomplete="off"
              :rules="requiredRules" />
          </v-flex>

          <v-flex
            xs12
            sm4
            class="full-flex">
            <v-text-field
              v-model="newPasswordConfirmation"
              label="Confirmação Nova Senha"
              type="password"
              autocomplete="off"
              :error-messages="newPasswordMatchError()"
              :rules="requiredRules" />
          </v-flex>
        </v-layout>
      </div>
    </v-form>
  </v-container>
</template>
<script>
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'
  export default {
    components: {},
    mixins: [ validate ],
    data () {
      return {
        valid: true,
        newPassword: '',
        newPasswordConfirmation: '',
        currentPassword: ''
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      })
    },
    methods: {
      changePassword () {
        if (this.$refs.form.validate()) {
          const data = {
            newPassword: this.newPassword,
            currentPassword: this.currentPassword
          }
          return this.$store
            .dispatch('user/changePassword', data)
            .then(this._success)
            .catch(this._failure)
        }
      },
      clear (){
        this.newPassword = ''
        this.newPasswordConfirmation = ''
        this.currentPassword = ''
      },
      newPasswordMatchError () {
        return (this.newPassword === this.newPasswordConfirmation) ? '' : 'Nova senha não coincide'
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Senha alterada com sucesso'})
        this.$emit('passwordChanged')
        this.$nextTick(() => this.clear())
      },
      _failure (message) {
        if ('auth/wrong-password' === message.code) {
          this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'Senha não confere'})
          return
        }
        if ('auth/weak-password' === message.code) {
          const message = 'Nova senha precisa ter no minimo 6(seis) caracteres'
          this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: message})
          return
        }
        this.clear()
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      }
    }
  }
</script>

