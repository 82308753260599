<template>
  <v-container class="container-page my-profile">
    <v-card class="mt-0 transparent">
      <h1 class="main-title">
        Meu perfil
      </h1>
      <template>
        <v-layout justify-center class="user">
          <v-flex xs12 sm8>
            <v-card>
              <v-card-title>
                <h2 class="title d-flex darkGray--text">
                  <v-icon color="darkGray">
                    {{ $root.icons.layout.user }}
                  </v-icon>
                  Dados pessoais
                </h2>
              </v-card-title>

              <form-user
                class="negative-mt"
                :items="userInfo"
                :display-mode="displayModeInfo"
                hide-submit
                actions-edit
                @displayMode="changeDisplayModeInfo"
                @save="saveEditInfo" />
            </v-card>

            <v-divider :dark="true" />

            <v-card v-if="!displayModeInfo">
              <v-card-title>
                <h2 class="title d-flex darkGray--text">
                  <v-icon color="darkGray">
                    {{ $root.icons.layout.password }}
                  </v-icon>
                  Alterar Senha
                </h2>
              </v-card-title>

              <form-change-password
                class="negative-mt"
                @passwordChanged="passwordChanged" />
            </v-card>

            <v-divider :dark="true" />

            <v-card v-if="!displayModeInfo">
              <v-card-title>
                <h2 class="title d-flex darkGray--text">
                  <v-icon color="darkGray">
                    {{ $root.icons.layout.user_avatar }}
                  </v-icon>
                  Alterar Avatar
                </h2>
              </v-card-title>

              <form-change-picture
                class="negative-mt"
                @pictureChanged="pictureChanged" />


              <!-- <v-divider></v-divider>

                <v-card-title>
                  <h2 class="title d-flex darkGray--text"><v-icon color="darkGray">{{$root.icons.layout.place}}</v-icon>Dados de endereço</h2>
                </v-card-title>

                <form-address
                  class="negative-mt"
                  :items="address"
                  :displayMode="displayModeInfo"
                  hideSubmit
                  @displayMode="changeDisplayModeInfo"
                  @save="saveEditInfo"
                  actionsEdit
                  >
              </form-address>-->
            </v-card>

            <v-divider :dark="true" />
          </v-flex>
        </v-layout>
      </template>
    </v-card>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :multi-line="snackbar.multiline"
      :vertical="snackbar.vertical"
      :top="snackbar.top"
      :bottom="snackbar.bottom"
      :left="snackbar.left"
      :right="snackbar.right">
      {{ snackbar.text }}
      <v-btn text @click.native="snackbar.show = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
  import FormUser from '@/components/ui/forms/FormUser'
  import snackbar from '@/mixins/snackbar'
  import FormChangePassword  from '@/components/ui/forms/FormChangePassword'
  import FormChangePicture  from '@/components/ui/forms/FormChangePicture'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      FormChangePicture,
      FormChangePassword,
      FormUser
    },
    mixins: [snackbar],
    data: () => ({
      loading: 0,
      active: null,
      displayModeInfo: true
    }),
    computed: {
      ...mapGetters({
        userInfo: 'user/getUserInfo'
      })
    },
    methods: {
      pictureChanged (){
        // this.changeDisplayModeInfo(true)
      },
      passwordChanged (){
        // this.changeDisplayModeInfo(true)
      },
      changeDisplayModeInfo(status) {
        this.displayModeInfo = status
      },
      savePicture(file) {
        const data = {
          id: this.$uuid.v4(),
          file
        }
        this.$store
          .dispatch('user/changePicture', data)
          .then(() => {
            this.$root.$emit('snackbar', {
              show: true,
              color: 'success',
              text: 'Imagem alterada'
            })
          })
          .catch(this._failure)
      },
      saveEditInfo(account) {
        this.$store
          .dispatch('user/update', account)
          .then(() => {
            this.$root.$emit('snackbar', {
              show: true,
              color: 'success',
              text: 'Conta atualizada'
            })
            this.$store.commit('user/setBirthDate', account.birthDate, { root: true })
          })
          .catch(this._failure)
      },
      _failure() {
        this.$nextTick(() => {
          this.$root.$emit('snackbar', {
            show: true,
            color: 'error',
            text: 'houve um problema'
          })
        })
      }
    }
  }
</script>

<style lang="sass">
  .my-profile
    .v-divider
      margin-top: 5px
      margin-bottom: 5px

    .negative-mt
      margin-top: -50px

    .title
      display: flex
      align-items: center

      .icon
        margin-right: 10px

    .layout
      align-items: center

</style>
